import { GoogleAuthProvider } from '@angular/fire/auth';
import { environment } from '../../environments/environment';

export const googleAuthProviderFactory = (): GoogleAuthProvider => {
  const googleAuthProvider = new GoogleAuthProvider();
  googleAuthProvider.setCustomParameters({
    hd: environment.allowedDomain,
  });

  return googleAuthProvider;
};
