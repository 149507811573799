import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatIconModule],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {
  private readonly authService: AuthService = inject(AuthService);
  private readonly router: Router = inject(Router);
  private readonly snackBar: MatSnackBar = inject(MatSnackBar);

  async authenticateWithGoogle(): Promise<void> {
    // TODO:
    // Implement a NotificationService to handle success, error and warn info notifications
    // ClickUp task: https://app.clickup.com/t/86a2j2gqf
    try {
      await this.authService.authenticateWithGoogle();
      this.router.navigateByUrl('/');
    } catch {
      this.snackBar.open('An error has occurred during the authentication process. Please try again!', 'OK');
    }
  }
}
