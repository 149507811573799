import { Signal, inject } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = async () => {
  const auth: Auth = inject(Auth);
  const user: Signal<User | null> = inject(AuthService).user;
  const router: Router = inject(Router);

  await auth.authStateReady();
  if (user()) return true;

  router.navigateByUrl('auth/authenticate');
  return false;
};
